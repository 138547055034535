<template>
  <div class="content-financial-accounts-all">
    <div class="content-txt-title-search">
      <p class="txt-title-financial-accounts">Cuentas financieras</p>
      <div class="search-filter-financial-accounts">
        <v-text-field
          v-model="sSearch"
          label="Buscar cuenta"
          placeholder="Buscar cuenta"
          class="global-text-field"
          color="var(--primary-color-border-input)"
          background-color="var(--primary-color-menu)"
          persistent-placeholder
          outlined
        >
        </v-text-field>
      </div>
    </div>
    <div v-if="!bMobile" class="content-financial-accounts-table">
      <report-financial-accounts-table
        :aHeaders="aHeaders"
        :aTable="aFinancialAccounts"
        :numPages="numPages"
        :totalItems="totalItems"
        @getEmitFinancialAccounts="getEmitFinancialAccounts"
      />
    </div>
    <div v-else class="content-financial-accounts-card">
      <report-financial-accounts-card
        :aHeaders="aHeaders"
        :aCard="aFinancialAccounts"
        :numPages="numPages"
        :totalItems="totalItems"
        @getEmitFinancialAccounts="getEmitFinancialAccounts"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    aFinancialAccounts: Array,
    bMobile: Boolean,
    numPages: Number,
    totalItems: Number,
  },
  data() {
    return {
      sSearch: "",
      aHeaders: [
        {
          text: "#",
          sortable: false,
          align: "center",
          value: "sNumber",
          class: "global-header-table body-table-number-account-receivable ",
          cellClass: "global-body-table body-table-number-account-receivable",
        },
        {
          text: "Nombre",
          sortable: false,
          value: "sName",
          class: "global-header-table ",
          cellClass: "global-body-table",
        },
        {
          text: "Institución",
          sortable: false,
          value: "sInstitution",
          class: "global-header-table ",
          cellClass: "global-body-table",
        },
        {
          text: "Tipo",
          sortable: false,
          value: "sType",
          class: "global-header-table ",
          cellClass: "global-body-table",
        },
        {
          text: "Variación",
          sortable: false,
          value: "dVariation",
          align: "end",
          class:
            "global-header-table header-table-payable-balance-account-receivable ",
          cellClass:
            "global-body-table body-table-payable-balance-account-receivable",
        },
        {
          text: "Balance",
          sortable: false,
          value: "dBalance",
          align: "end",
          class:
            "global-header-table header-table-payable-balance-account-receivable ",
          cellClass:
            "global-body-table body-table-payable-balance-account-receivable",
        },
      ],
    };
  },
  methods: {
    getEmitFinancialAccounts(obj) {
      this.$emit("getEmitFinancialAccounts", obj);
    },
  },
  watch: {
    sSearch() {
      this.$emit("setSearchFinancialAccounts", this.sSearch);
    },
  },
};
</script>
<style scoped>
.content-financial-accounts-all {
  /* margin-top: 45px; */
  margin-bottom: 25px;
}
.content-txt-title-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.txt-title-financial-accounts {
  color: var(--primary-color-text);
  font-family: "pop-SemiBold";
  font-size: 18px;
}
.search-filter-financial-accounts {
  width: 350px;
}

/*Plantilla media query css responsive*/

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-txt-title-search {
    display: block;
    align-items: center;
    justify-content: space-between;
  }

  .txt-title-financial-accounts {
    color: var(--primary-color-text);
    font-family: "pop-SemiBold";
    font-size: 18px;
  }
  .search-filter-financial-accounts {
    width: 100%;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>
